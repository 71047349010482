export default {
  items: [],
  selectedIds: [],
  isCreating: false,
  isEditing: false,
  isSearching: false,
  pageSize: 25,
  pageOffset: 0,
  layoutType: "compact",
  quickFilters: [
    {
      value: "horoscope_microsite",
      label: "Horoscope"
    }
  ]
};
